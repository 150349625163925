/** @format */

import Fuse from "fuse.js";
import { IExerciseWithImage } from "../../Main/PlanEdit/PlanEdit";

export class ExerciseSearch {
    private static constructSearchEngine(items: IExerciseWithImage[]) {
        const options: Fuse.IFuseOptions<IExerciseWithImage> = {
            threshold: 0.2,
            distance: 30,
            shouldSort: true,
            ignoreLocation: true,
            ignoreFieldNorm: true,
            keys: [
                { name: "name", weight: 0.9 },
                { name: "searchWords", weight: 0.01 },
            ],
            findAllMatches: true,
        };

        const fuse = new Fuse(items, options);
        return fuse;
    }

    public static search(items: IExerciseWithImage[], text: string): IExerciseWithImage[] {
        if (text.trim() === "") {
            return items;
        }

        const fuse = this.constructSearchEngine(items);
        const fuseResult: any[] = fuse.search(text);
        const results = fuseResult.map(r => r.item);
        return results;
    }
}
