/** @format */

import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import "./Payment.css";
import { UserService } from "../../../Api/User";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { UseLoading } from "../../../Shared/Loading/UseLoading";

export interface IPaymentProps {
    showSettingsPanel: () => void;
}

export const Payment = (props: IPaymentProps) => {
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(1);
    const useLoader = UseLoading();

    const payNow = async () => {
        useLoader.startLoading();
        const stripe = await loadStripe(
            process.env.REACT_APP_STRIPE_PUBLIC_KEY ??
                "pk_test_51LQWOYJ3wOMmVBLrX6QZsbpSlJu1m1s6lxRyUupXNr01qUhSB0V5V5aUOLWIqaJTDZaDKY8TsvGSqovPH4Gjo2nO00uEp0wBwq"
        );

        const checkoutSessionId = await UserService.PaymentCheckoutAsync(quantity, price);
        useLoader.stopLoading();

        if (!stripe) return alert("Klarte ikke laste innbetaling, prøv igjen senere");
        if (!checkoutSessionId) return alert("Klarte ikke finne innbetalingsserver, prøv igjen senere");

        stripe.redirectToCheckout({
            sessionId: checkoutSessionId,
        });
    };

    let priceInfo = "måned";
    let priceSum = quantity * 120;

    if (price === 2) {
        priceInfo = "halvt år";
        priceSum = quantity * 720;
    }

    if (price === 3) {
        priceInfo = "år";
        priceSum = quantity * 1440;
    }

    return (
        <div className="Payment">
            <div className="info">
                <h1>Velg ditt abonnement</h1>
                <p>
                    Repset koster kun kr. 120,- per måned eks.mva per person som skal bruke programmet. Hvis man bruker samme konto på flere PC´er, så er dette
                    antall PC´er. En pålogget PC telles som aktiv i 10 dager. Dermed vil man trenge lisenser til antall aktive personer som skal logge seg på
                    programmet. Om du er usikker på hva du trenger akkurat nå, kan du enkelt oppgradere senere. Se og endre din konto i{" "}
                    <a
                        onClick={async (e) => {
                            e.preventDefault();
                            props.showSettingsPanel();
                        }}
                        href=""
                    >
                        Innstillinger
                    </a>{" "}
                </p>
            </div>

            <div className="btns">
                <button
                    style={{
                        borderTopLeftRadius: "var(--border-radius-main)",
                        borderBottomLeftRadius: "var(--border-radius-main",
                    }}
                    onClick={() => setPrice(1)}
                    className={price === 1 ? "selected" : undefined}
                >
                    Betale per måned
                </button>
                <button
                    style={{ borderLeft: "2px solid #cbd2d9", borderRight: "2px solid #cbd2d9" }}
                    onClick={() => setPrice(2)}
                    className={price === 2 ? "selected" : undefined}
                >
                    Betale per 6 måneder
                </button>
                <button
                    style={{
                        borderTopRightRadius: "var(--border-radius-main)",
                        borderBottomRightRadius: "var(--border-radius-main",
                    }}
                    onClick={() => setPrice(3)}
                    className={price === 3 ? "selected" : undefined}
                >
                    Betale per år
                </button>
            </div>

            <div className="product">
                <h1>Antall personer</h1>
                <p>
                    <b>{priceSum}kr</b> / {priceInfo}
                </p>

                <div className="counter">
                    <button
                        className="Button Secondary"
                        disabled={quantity < 2}
                        onClick={(e) => {
                            e.preventDefault();
                            setQuantity(quantity - 1);
                        }}
                    >
                        <IoChevronDown />
                    </button>

                    <h2>{quantity}</h2>

                    <button
                        className="Button Secondary"
                        disabled={quantity > 950}
                        onClick={(e) => {
                            e.preventDefault();
                            setQuantity(quantity + 1);
                        }}
                    >
                        <IoChevronUp />
                    </button>
                </div>
            </div>

            <div className="cta">
                <button disabled={useLoader.isLoading} onClick={payNow} className="Button Primary PayNowBtn">
                    Betal nå
                </button>

                {useLoader.loadingComponent}
            </div>
        </div>
    );
};
